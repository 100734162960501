
//---------------------
//로그인
//---------------------
const loanRouter = [
    //메인
    {
        path: "/loan",
        name: "loan",
        component: () => import("../views/loan/LoanMainView.vue"),
    },

    // 로그인
    {
        path: "/loan/login",
        name: "loan-login",
        component: () => import("../views/loan/LoanLoginView.vue"),
    },

    // 정보
    {
        path: "/loan/age",
        name: "loan-age",
        component: () => import("../views/loan/info/LoanAgeView.vue"),
    },
    {
        path: "/loan/house",
        name: "loan-house",
        component: () => import("../views/loan/info/LoanHouseView.vue"),
    },
    {
        path: "/loan/income",
        name: "loan-income",
        component: () => import("../views/loan/info/LoanIncomeView.vue"),
    },
    {
        path: "/loan/hold",
        name: "loan-hold",
        component: () => import("../views/loan/info/LoanHoldView.vue"),
    },
    {
        path: "/loan/hold-active",
        name: "loan-hold-active",
        component: () => import("../views/loan/info/LoanHoldActiveView.vue"),
    },
    {
        path: "/loan/hold-repay",
        name: "loan-hold-repay",
        component: () => import("../views/loan/info/LoanHoldRepayView.vue"),
    },
    {
        path: "/loan/property",
        name: "loan-property",
        component: () => import("../views/loan/info/LoanPropertyView.vue"),
    },
    {
        path: "/loan/condition",
        name: "loan-condition",
        component: () => import("../views/loan/info/LoanConditionView.vue"),
    },
    {
        path: "/loan/difficult",
        name: "loan-difficult",
        component: () => import("../views/loan/info/LoanDifficultView.vue"),
    },

    // 데이터
    {
        path: "/loan/gov-analyze",
        name: "loan-gov-analyze",
        component: () => import("../views/loan/data/gov/LoanGovAnalyzeView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },    

    // 가능
    // 가능의 analyze는 a-b테스트 중
    {
        path: "/loan/gov/possible",
        name: "loan-gov-possible",
        component: () => import("../views/loan/data/gov/LoanPossibleGovView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    
    // 불가능
    {
        path: "/loan/gov/impossible",
        name: "loan-gov-impossible",
        component: () => import("../views/loan/data/gov/LoanImPossibleGovView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
    {
        path: "/loan/gov/impossible/analyze",
        name: "loan-gov-impossible-analyze",
        component: () => import("../views/loan/data/gov/LoanImpossibleGovAnalyzeReasonView.vue"),
        beforeEnter: (to, from, next) => {
            if (localStorage.naezip_to_ac_se) {
                next();
            }
            else {
                next("/");
            }
        },
    },
];

export default loanRouter;
